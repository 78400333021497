import { Routes, Route, Link, useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import NavItem from 'react-bootstrap/NavItem';
import { FiUserCheck, FiUserX } from 'react-icons/fi';
import type { ReactNode } from 'react';
import styles from './App.module.css';
import Home from './pages/Home';
import Task from './pages/Task';
import TagList from './pages/Tag/List';
import TagDetail from './pages/Tag/Detail';
import Login from './pages/Login';
import History from './pages/History';
import Settings from './pages/Settings';
import { load } from './core/localStorage';
import Report from './pages/Report';

type LinkItemProps = {
  url: string;
  text: ReactNode;
  pathname: string;
};

const LinkItem = ({ url, text, pathname }: LinkItemProps) => {
  return pathname === url ? (
    <p className={styles.active}>{text}</p>
  ) : (
    <Link
      className={`${styles.link} ${pathname === url ? 'active' : ''}`}
      to={url}
    >
      {text}
    </Link>
  );
};

function App() {
  const location = useLocation();

  const baseUrl = load('BASE_URL');
  const appAuth = load('APP_AUTH');

  const isLoggedIn = !!baseUrl && !!appAuth;
  const LoginIcon = isLoggedIn ? FiUserCheck : FiUserX;
  const loginColor = isLoggedIn ? '#555' : '#EEE';

  return (
    <>
      <Nav variant="tabs" className={styles.nav}>
        <div>
          <NavItem>
            <LinkItem url="/" text="Home" pathname={location.pathname} />
          </NavItem>
          <NavItem>
            <LinkItem
              url="/task/0"
              text="+ Task"
              pathname={location.pathname}
            />
          </NavItem>
          <NavItem>
            <LinkItem
              url="/report"
              text="+ Report"
              pathname={location.pathname}
            />
          </NavItem>
          <NavItem>
            <LinkItem url="/tag" text="Tags" pathname={location.pathname} />
          </NavItem>

          <NavItem>
            <LinkItem
              url="/history"
              text="History"
              pathname={location.pathname}
            />
          </NavItem>
          <NavItem>
            <LinkItem
              url="/settings"
              text="Settings"
              pathname={location.pathname}
            />
          </NavItem>
        </div>
        <div>
          <NavItem>
            <LinkItem
              url="/login"
              text={<LoginIcon fill={loginColor} />}
              pathname={location.pathname}
            />
          </NavItem>
        </div>
      </Nav>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/task/:id" element={<Task />} />
        <Route path="/tag" element={<TagList />} />
        <Route path="/tag/:id" element={<TagDetail />} />
        <Route path="/login" element={<Login />} />
        <Route path="/history" element={<History />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/report" element={<Report />} />
      </Routes>
    </>
  );
}

export default App;
