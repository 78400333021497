import { useState } from 'react';
import { Container, Button } from 'react-bootstrap';

const Settings = () => {
  const [battery, setBattery] = useState('-');
  const [notificationPermission, setNotificationPermission] = useState(
    Notification.permission,
  );

  const onClick = () => {
    if ((navigator as any).getBattery) {
      (navigator as any).getBattery().then((x: any) => {
        setBattery(`charging: ${x.charging ? 'Yes' : 'No'}`);
      });
    }
  };

  const onTestNotification = () => {
    new Notification('Test');
  };

  const onAskNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
    setNotificationPermission(permission);
  };

  return (
    <Container style={{ marginTop: 10 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Button color="primary" onClick={onClick} style={{ marginBottom: 5 }}>
          Get more Data
        </Button>
        <Button
          color="primary"
          onClick={onAskNotificationPermission}
          style={{ marginBottom: 5 }}
        >
          Ask for Notification Permission
        </Button>
        <Button
          color="primary"
          onClick={onTestNotification}
          style={{ marginBottom: 5 }}
        >
          Test Notification
        </Button>
      </div>
      <p>
        <strong>Notification Permission:</strong> {notificationPermission}
      </p>
      <p>
        <strong>window.Notification exists:</strong>{' '}
        {window.Notification ? 'Yes' : 'No'}
      </p>
      <p>
        <strong>Battery:</strong> {battery}
      </p>
      <p>
        <strong>userAgent:</strong> {navigator.userAgent}
      </p>
      <p>
        <strong>current language:</strong> {navigator.language}
      </p>
      <p>
        <strong>available languages:</strong> {navigator.languages.join(',')}
      </p>
      // //
    </Container>
  );
};

export default Settings;
