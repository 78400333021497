import { ComponentProps, useState } from 'react';
import { format, sub as dateSub, add as dateAdd } from 'date-fns';
import {
  FiCheckSquare,
  FiSquare,
  FiTrash2,
  FiSkipForward,
  FiSkipBack,
} from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { Container, Form, InputGroup, Spinner } from 'react-bootstrap';
import type { RootState } from '../../core/redux/store';
import { add, done } from '../../core/tasks/taskSlice';
import PillWithX from '../../components/PillWithX';
import { useAppSelector } from '../../core/redux/useAppSelector';
import { useAppDispatch } from '../../core/redux/useAppDispatch';
import Button from '../../components/Button';
import styles from './Report.module.css';

type Task = RootState['tasks']['tasks'][0];

type InputFieldProps = {
  label: string;
  value: ComponentProps<typeof Form.Control>['value'];
  onChange: (newValue: string) => void;
  displayAfter?: string;
  type: ComponentProps<typeof Form.Control>['type'];
  disabled?: boolean;
  step?: number;
};

const InputField = ({
  label,
  value,
  onChange,
  displayAfter,
  disabled,
  step,
  type,
}: InputFieldProps) => (
  <InputGroup className="mb-1">
    <InputGroup.Text>{label}</InputGroup.Text>
    {type === 'textarea' ? (
      <Form.Control
        disabled={disabled}
        type="textarea"
        value={value}
        as={'textarea'}
        rows={3}
        onChange={({ target: { value } }) => {
          onChange(value);
        }}
      />
    ) : (
      <Form.Control
        step={step}
        disabled={disabled}
        type={type}
        value={value}
        onChange={({ target: { value } }) => {
          onChange(value);
        }}
      />
    )}
    {displayAfter && <InputGroup.Text>{displayAfter}</InputGroup.Text>}
  </InputGroup>
);

const Report = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loadingAll = useAppSelector((x) => x.tasks.loadingAll);
  const loadingChange = useAppSelector((x) => x.tasks.loadingChange);
  const tags = useAppSelector((x) => x.tags.tags);

  const now = new Date();
  now.setHours(6);
  now.setMinutes(30);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState<Date | undefined>(now);
  const [endDate, setEndDate] = useState<Date | undefined>(now);
  const [startEndDateSame, setStartEndDateSame] = useState(true);
  const [priority, setPriority] = useState<Task['priority']>(0);
  const [chosenTags, setChosenTags] = useState<number[]>([]);
  const [tagSelect, setTagSelect] = useState<number>(0);

  const onAdd = async () => {
    const actionAdd = await dispatch(
      add({
        title,
        description,
        startDate: startDate ? startDate.toISOString() : undefined,
        endDate: endDate ? endDate.toISOString() : undefined,
        priority,
        repeat: '',
        repeatType: 'completionDate',
        tagIds: chosenTags,
        optional: false,
      }),
    );

    if (actionAdd.type !== add.fulfilled.type) {
      alert('Something went wrong when Adding');
      return;
    }

    const taskId = actionAdd.payload as Task['id'];

    const actionDone = await dispatch(done(taskId));

    if (actionDone.type === done.fulfilled.type) {
      navigate('/');
    } else {
      alert('Something went wrong when Mark as Done');
    }
  };

  const onTodayClick = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const date = now.getDate();
    const hour = 6;
    const minute = 30;
    setStartDate(new Date(year, month, date, hour, minute));
    setEndDate(new Date(year, month, date, hour, minute));
  };

  const onDateLeftClick = (date: Date, setDate: (date: Date) => void) => {
    const newDate = dateSub(date, { days: 1 });
    setDate(newDate);
  };
  const onDateRightClick = (date: Date, setDate: (date: Date) => void) => {
    const newDate = dateAdd(date, { days: 1 });
    setDate(newDate);
  };
  const onTimeLeftClick = (date: Date, setDate: (date: Date) => void) => {
    const newDate = dateSub(date, { minutes: 30 });
    setDate(newDate);
  };
  const onTimeRightClick = (date: Date, setDate: (date: Date) => void) => {
    const newDate = dateAdd(date, { minutes: 30 });
    setDate(newDate);
  };

  return (
    <Container style={{ marginTop: 10, maxWidth: 600 }}>
      <h2>Add Report</h2>
      <InputField label="Title" value={title} onChange={setTitle} type="text" />
      <InputField
        label="descr."
        value={description}
        onChange={setDescription}
        type="textarea"
      />

      <div>
        <Button
          variant="outline-primary"
          type="button"
          style={{ marginRight: '.2rem' }}
          onClick={() => {
            onTodayClick();
          }}
          content="Today"
        />

        <Button
          variant="outline-primary"
          type="button"
          onClick={() => {
            setStartEndDateSame(!startEndDateSame);
          }}
          content={
            startEndDateSame ? (
              <>
                <FiCheckSquare style={{ marginRight: '.2rem' }} />
                Start = End
              </>
            ) : (
              <>
                <FiSquare style={{ marginRight: '.2rem' }} />
                Start != End
              </>
            )
          }
        />
      </div>

      <div className={styles.datetimeContainer}>
        {!startEndDateSame && (
          <InputGroup className={styles.startDatetimeContainer}>
            <div className={styles.dateContainer}>
              <Button
                variant="outline-primary"
                type="button"
                className={styles.dateLeftButton}
                onClick={() => {
                  onDateLeftClick(startDate || new Date(), setStartDate);
                }}
                content={<FiSkipBack />}
              />
              <InputField
                label="Start Date"
                value={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
                onChange={(newValue) => {
                  if (!newValue) {
                    return;
                  }
                  const now = new Date();
                  const year =
                    Number(newValue.split('-')[0]) ?? now.getFullYear();
                  const month =
                    Number(newValue.split('-')[1]) - 1 ?? now.getMonth();
                  const date = Number(newValue.split('-')[2]) ?? now.getDate();
                  const hour =
                    startDate?.getHours() ?? endDate?.getHours() ?? 6;
                  const minute =
                    startDate?.getMinutes() ?? endDate?.getMinutes() ?? 30;
                  setStartDate(new Date(year, month, date, hour, minute));
                }}
                type="date"
              />
              <Button
                variant="outline-primary"
                type="button"
                className={styles.dateRightButton}
                onClick={() => {
                  onDateRightClick(startDate || new Date(), setStartDate);
                }}
                content={<FiSkipForward />}
              />
            </div>

            <div className={styles.dateContainer}>
              <Button
                variant="outline-primary"
                type="button"
                className={styles.dateLeftButton}
                onClick={() => {
                  onTimeLeftClick(startDate || new Date(), setStartDate);
                }}
                content={<FiSkipBack />}
              />
              <InputField
                label="Start Time"
                value={startDate ? format(startDate, 'HH:mm') : ''}
                onChange={(newValue) => {
                  if (!newValue) {
                    return;
                  }
                  const now = new Date();
                  const year = startDate?.getFullYear() ?? now.getFullYear();
                  const month = startDate?.getMonth() ?? now.getMonth();
                  const date = startDate?.getDate() ?? now.getDate();
                  const hour = Number(newValue.split(':')[0]);
                  const minute = Number(newValue.split(':')[1]);
                  setStartDate(new Date(year, month, date, hour, minute));
                }}
                type="time"
              />
              <Button
                variant="outline-primary"
                type="button"
                className={styles.dateRightButton}
                onClick={() => {
                  onTimeRightClick(startDate || new Date(), setStartDate);
                }}
                content={<FiSkipForward />}
              />
            </div>
            <div style={{ display: 'block', width: '100%' }}>
              <Button
                variant="outline-danger"
                type="button"
                onClick={() => {
                  setStartDate(undefined);
                }}
                content={<FiTrash2 />}
              />
            </div>
          </InputGroup>
        )}

        <InputGroup className={styles.endDatetimeContainer}>
          <div className={styles.dateContainer}>
            <Button
              variant="outline-primary"
              type="button"
              className={styles.dateLeftButton}
              onClick={() => {
                onDateLeftClick(endDate || new Date(), setEndDate);
                if (startEndDateSame) {
                  onDateLeftClick(startDate || new Date(), setStartDate);
                }
              }}
              content={<FiSkipBack />}
            />
            <InputField
              label={startEndDateSame ? 'Date' : 'End Date'}
              value={endDate ? format(endDate, 'yyyy-MM-dd') : ''}
              onChange={(newValue) => {
                if (!newValue) {
                  return;
                }
                const now = new Date();
                const year =
                  Number(newValue.split('-')[0]) ?? now.getFullYear();
                const month =
                  Number(newValue.split('-')[1]) - 1 ?? now.getMonth();
                const date = Number(newValue.split('-')[2]) ?? now.getDate();
                const hour = endDate?.getHours() ?? startDate?.getHours() ?? 6;
                const minute =
                  endDate?.getMinutes() ?? startDate?.getMinutes() ?? 30;
                setEndDate(new Date(year, month, date, hour, minute));

                if (startEndDateSame) {
                  setStartDate(new Date(year, month, date, hour, minute));
                }
              }}
              type="date"
            />
            <Button
              variant="outline-primary"
              type="button"
              className={styles.dateRightButton}
              onClick={() => {
                onDateRightClick(endDate || new Date(), setEndDate);
                if (startEndDateSame) {
                  onDateRightClick(startDate || new Date(), setStartDate);
                }
              }}
              content={<FiSkipForward />}
            />
          </div>
          <div className={styles.dateContainer}>
            <Button
              variant="outline-primary"
              type="button"
              className={styles.dateLeftButton}
              onClick={() => {
                onTimeLeftClick(endDate || new Date(), setEndDate);
                if (startEndDateSame) {
                  onTimeLeftClick(startDate || new Date(), setStartDate);
                }
              }}
              content={<FiSkipBack />}
            />
            <InputField
              label={startEndDateSame ? 'Time' : 'End Time'}
              value={endDate ? format(endDate, 'HH:mm') : ''}
              onChange={(newValue) => {
                if (!newValue) {
                  return;
                }
                const now = new Date();
                const year = endDate?.getFullYear() || now.getFullYear();
                const month = endDate?.getMonth() || now.getMonth();
                const date = endDate?.getDate() || now.getDate();
                const hour = Number(newValue.split(':')[0]);
                const minute = Number(newValue.split(':')[1]);
                setEndDate(new Date(year, month, date, hour, minute));

                if (startEndDateSame) {
                  setStartDate(new Date(year, month, date, hour, minute));
                }
              }}
              type="time"
            />
            <Button
              variant="outline-primary"
              type="button"
              className={styles.dateRightButton}
              onClick={() => {
                onTimeRightClick(endDate || new Date(), setEndDate);
                if (startEndDateSame) {
                  onTimeRightClick(startDate || new Date(), setStartDate);
                }
              }}
              content={<FiSkipForward />}
            />
          </div>
          <div style={{ display: 'block', width: '100%' }}>
            <Button
              variant="outline-danger"
              type="button"
              onClick={() => {
                setEndDate(undefined);
              }}
              content={<FiTrash2 />}
            />
          </div>
        </InputGroup>
      </div>

      <InputGroup style={{ marginBottom: 5 }}>
        <InputGroup.Text>Priority</InputGroup.Text>
        <Form.Select
          value={priority}
          onChange={(event) => {
            setPriority(
              (event.target.value as unknown as Task['priority'] | undefined) ||
                0,
            );
          }}
        >
          {([0, 1, 2] as Task['priority'][]).map((x) => (
            <option key={x} value={x}>
              {x}
            </option>
          ))}
        </Form.Select>
      </InputGroup>

      <div style={{ marginBottom: 10 }}>
        <InputGroup style={{ marginBottom: 5 }}>
          <InputGroup.Text>Tags</InputGroup.Text>
          <Form.Select
            value={tagSelect}
            onChange={(event) => {
              setTagSelect(Number(event.target.value));
            }}
          >
            <option></option>
            {tags.map((x) => (
              <option key={x.id} value={x.id}>
                {x.name}
              </option>
            ))}
          </Form.Select>
          <Button
            variant="outline-primary"
            disabled={chosenTags.includes(tagSelect) || !tagSelect}
            onClick={() => {
              setChosenTags([...chosenTags, tagSelect]);
            }}
            content="ADD"
          />
        </InputGroup>
        {chosenTags.map((x) => {
          const tag = tags.find((t) => t.id === x);
          return (
            <PillWithX
              key={x}
              name={tag?.name || 'Unknown'}
              bgColor={tag?.bgColor || ''}
              textColor={tag?.textColor || ''}
              onClick={() => {
                setChosenTags(chosenTags.filter((t) => t !== x));
              }}
            />
          );
        })}
      </div>

      <InputGroup className="mb-3 mt-3">
        <Button
          disabled={loadingAll || loadingChange}
          variant="success"
          type="button"
          onClick={() => {
            onAdd();
          }}
          content={
            loadingAll || loadingChange ? (
              <Spinner animation="border" size="sm" />
            ) : (
              'ADD'
            )
          }
        />
      </InputGroup>
    </Container>
  );
};

export default Report;
