import request from '../request';
import type { Task } from './types';

async function getAll(done?: 'y' | 'n') {
  let path = '/tasks';
  if (done) {
    path += `?done=${done}`;
  }
  const data = await request<Task[]>({ path, method: 'GET' });
  return data;
}

export default getAll;
